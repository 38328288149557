<i18n lang="json">
{
	"ru": {
    "pay": "Оплата при заселении",
    "precost": "Внести предоплату сегодня",
    "noMb": "Пока платить не нужно.\nСначала дождитесь подтверждения бронирования.",
    "precost2": "Предоплата",
    "from": "от ",
    "warn": "Предоплата не менее {n}. Хозяин жилья укажет точную сумму при подтверждении брони.",
    "uponCheckIn": "Расчётная цена указана в валюте: {n}. Обратите внимание, что в соответствии с законодательством страны пребывания, вам может потребоваться произвести расчет в национальной валюте по курсу на момент заселения.",
    "centerCourse": "Курс ЦБ РФ на {n}"
	},
	"en": {
    "pay": "Pay of check-in",
    "precost": "Give precost today",
    "noMb": "You don't need to pay yet.\nFirst wait for the booking confirmation.",
    "precost2": "Prepayment",
    "from": "from ",
    "warn": "Prepayment not less than {n}. The host will indicate the exact amount at the time of booking confirmation.",
    "uponCheckIn": "The estimated price is shown in currency: {n}. Please pay attention that in accordance with the legislation of the host country, you may be required to make a payment in local currency at the rate at the time of check-in.",
    "centerCourse": "The exchange rate of the Central Bank of the Russian Federation {n}"
  }
}
</i18n>
<template>
  <div class="calc-notifications">
    <template v-if=" !isInstant && !canHold && isBusinessScheme">
      <div class="calc-notifications__pay">
        <span class="calc-notifications__popover">
          {{ t("precost2") }}&nbsp;
          <VDropdown
            theme="tooltip"
            distance="10"
            placement="top"
            :triggers="['click']"
            popper-class="v-popper__popper--max-width-300"
          >
            <span class="icon-app-question"></span>
            <template #popper>
              <div class="tooltip-text">
                <p class="text-left" v-html="t('warn', { n: precost })"></p>
              </div>
            </template>
          </VDropdown>
        </span>
        <p>{{ t("from") }} <span class="text-bold">{{ precost }}</span></p>
      </div>
      <div class="calc-notifications__pay">
        <p>{{ t("pay") }}</p>
        <p>{{ price }}</p>
      </div>
    </template>
    <template v-else-if="bookingNow || canHold">
      <div class="calc-notifications__pay">
        <p>{{ t("precost") }}</p>
        <p class="text-bold">{{ precost }}</p>
      </div>
      <template
        v-if="
          getObjectOriginalCurrency?.id == this.getObjectPrice?.currency?.id
        "
      >
        <div class="calc-notifications__pay">
          <p>{{ t("pay") }}</p>
          <p>{{ price }}</p>
        </div>
      </template>
      <div v-else class="payment-arrival">
        <div class="payment-arrival__top">
          <p>{{ t("pay") }}</p>
          <p class="payment-arrival__price-qustion">
            {{ currencyPrice }}
            <VDropdown
              class="bonus__popover"
              distance="5"
              placement="top"
              :triggers="['click']"
              popper-class="v-popper__popper--max-width-300"
            >
              <span class="ico-question">?</span>
              <template #popper>
                <div class="tooltip-text">
                  <p class="text-left" v-html="t('uponCheckIn', { n: lowerCurrrencyTitle })"></p>
                  <div v-close-popper class="tooltip-close"></div>
                </div>
              </template>
            </VDropdown>
          </p>
        </div>
        <div class="payment-arrival__description">
          <p class="payment-arrival__text">
            {{ t("centerCourse", { n: currentDate }) }}
          </p>
          <p class="payment-arrival__price">
            ~{{ price }}
          </p>
        </div>
      </div>
    </template>
    <!--<div v-else class="calc-notifications__no-mb">{{ t("noMb") }}</div>-->
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { useI18n } from 'vue-i18n';
import { formatPriceCeilForCurrency, formatPriceFloorForCurrency } from "@/utils/format";

export default {
  name: "CalcNotifications",
  props: {
    sale: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      rate: null,
      loadCurrencyList: true,
      currentCurrencyPrice: null
    }
  },
  watch: {
    userCurrencyId: {
      deep: true,
      handler() {
        this.loadCurrencyList = false;
        if (
          this.getObjectOriginalCurrency?.id !=
          this.getObjectPrice?.currency?.id
        ) {
          this.setRates(this.getObjectPrice?.currency?.id)
            .then((response) => {
              this.rate = response[this.getObjectOriginalCurrency.id];
              this.changeUserCurrency = true;
            })
            .finally(() => {
              this.loadCurrencyList = true;
            });
        }
      }
    }
  },
  computed: {
    ...mapState("booking", ["canHold"]),
    ...mapGetters("booking", ["bookingPrice", "bookingNow"]),
    ...mapGetters("detail", [
      "getObjectPermissions",
      "getPriceCurrency",
      "getObjectOriginalCurrency",
      "getObjectPrice",
    ]),
    ...mapGetters("detailBooking", ["isInstant"]),
    isBusinessScheme(){
      if (!this.getObjectPermissions) return false;
      if(
        this.getObjectPermissions.includes("hasOwnerServiceConditionBusiness") ||
        this.getObjectPermissions.includes("hasOwnerServiceConditionBusinessmaximum")
      ) return true;

      return false;
    },
    precost() {
      if(!this.bookingPrice) {
        return 0;
      }
      return formatPriceCeilForCurrency(
        this.bookingPrice?.precost - this.sale,
        this.bookingPrice.currency.code,
        this.$i18n.locale
      );
    },
    price() {
      if(!this.bookingPrice || !this.bookingPrice?.price ) {
        return 0;
      }
      return formatPriceFloorForCurrency(
        this.bookingPrice?.price - this.bookingPrice?.precost,
        this.bookingPrice.currency.code,
        this.$i18n.locale
      );
    },
    currencyPrice() {
      if(!this.loadCurrencyList || !this.getObjectPrice?.price) {
        return this.currentCurrencyPrice;
      }
      const precost = this.getObjectPrice?.precost || 0;
      let price = null;
      price = (this.getObjectPrice?.price - precost) / this.rate;
      const currency = new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.getObjectOriginalCurrency.code,
        minimumFractionDigits: 0,
      }).format(price);
      this.currentCurrencyPrice = currency;
      return currency;
    },
    currentDate() {
      let now = new Date();
      const day = now.toLocaleString("ru-RU", {
        day: "numeric",
      });
      const month = now
        .toLocaleString("ru-RU", {
          month: "short",
      }).replace(".", "");
      const year = now
        .toLocaleString("ru-RU", {
          year: "numeric",
      })

      return day + ' ' + month + ' ' + year;
    },
    userCurrencyId() {
      return this.getObjectPrice?.price;
    },
    lowerCurrrencyTitle() {
      return this.getObjectOriginalCurrency.title[0].toLowerCase() + this.getObjectOriginalCurrency.title.slice(1);
    }
  },
  methods: {
    ...mapActions("detail", ["setRates"])
  },
  mounted() {
    if(this.getObjectOriginalCurrency?.id != this.getObjectPrice?.currency?.id) {
      this.setRates(this.getObjectPrice?.currency?.id)
      .then((response) => {
        this.rate = response[this.getObjectOriginalCurrency.id];
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.calc-notifications {

  &__popover{
    display: flex;
    align-items: center;

    .icon-app-question{
      font-size: 15px;
    }
  }
  &__pay-business{
    display: flex;
    align-items: center;

    span{
      line-height: 1px;
      font-size: 15px;
    }
  }

  &__pay {
    height: 42px;
    background: #f6f6f6;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 3px;

    p {
      margin-top: 0 !important;
    }
  }

  &__no-mb {
    padding-top: 10px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    white-space: pre-line;
  }

  .text-bold {
    font-weight: bold;
  }
}

.payment-arrival {
  padding: 12px 9px 12px 8px;
  border-radius: 3px;
  background-color: #f6f6f6;
  margin-top: 8px;
  margin-bottom: 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #717171;
  }

  &__price-qustion {
    display: flex;
    align-items: flex-end;
  }

  p {
    margin: 0;
  }

  .ico-question {
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 0 1px 6px;
    font-size: 10px;
  }
}
</style>
