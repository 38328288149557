<template>
  <div v-if="bookingPriceError.length" class="booking-price-error">
    <p v-for="(error, index) in bookingPriceError" :key="index">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BookingPriceError",
  computed: {
    ...mapGetters("booking", ["bookingPriceError"]),
  },
};
</script>

<style lang="scss" scoped>
.booking-price-error {
  background: #fdd2d2;
  color: #e10600;
  font-size: 13px;
  line-height: 17px;
  padding: 10px 10px 10px 36px;
  position: relative;
  width: 100%;
}
</style>
